import React from 'react';
import PropTypes from 'prop-types';

const FormSelect = ({
  value = 'Select Country',
  className = '',
  name,
  label = '',
  onChange,
  onBlur,
  required = false,
  options = [],
  error = '',
  ...props
}) => {
  return (
    <div className={`${className}`}>
      {label && (
        <label className="text-sm text-gray-600" htmlFor={name}>
          {label}
        </label>
      )}
      <select
        className="h-12 px-2 border border-gray-500 rounded w-full"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      >
        <option value="Select Country" disabled>
          Select Country
        </option>
        {options.map((option, i) => (
          <option key={`op-${i}`} value={option}>
            {option}
          </option>
        ))}
      </select>
      {error && (
        <div className="text-red-600 text-sm leading-none mt-1">{error}</div>
      )}
    </div>
  );
};

FormSelect.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default FormSelect;
