// system inmports
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

// components imports
import FormItem from '@/components/FormItem';
import FormSelect from '@/components/FormSelect';
import FormCheckbox from '@/components/FormCheckbox';
import Footer from '@/components/Footer';

// images imports
import heroMobile from '@/assets/img/hero-mobile.png';
import hero from '@/assets/img/hero.png';
import spinner from '@/assets/img/spinner.gif';
import thumbUp from '@/assets/img/thump-up.png';
import select from '@/assets/img/select.svg';
import fightfulCom from '@/assets/img/fightfulcom.svg';

const HomePage = () => {
  const [formStatus, setFormStatus] = useState('IDLE');

  const [formData, setFormData] = useState({
    first: '',
    last: '',
    email: '',
    country: 'Select Country',
    terms: false,
    recaptcha: false,
  });

  const [formError, setFormError] = useState({
    first: '',
    last: '',
    email: '',
    country: '',
    terms: '',
    recaptcha: '',
  });

  useEffect(() => {
    const isUserRegistered = localStorage.getItem('userRegister');
    if (isUserRegistered) {
      setFormStatus('COMPLETE');
    }
  }, []);

  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const type = e.target.type;

    // Reset the error for the specified field
    if (value) {
      setFormError({
        ...formError,
        [name]: '',
      });
    }

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? !formData[name] : value,
    });
  };

  const validateEmail = (email) => {
    let format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return format.test(email);
  };

  const isValid = () => {
    let hasError = false;

    let errorName = formError;

    if (!formData.first) {
      errorName.first = 'This field is required';
      hasError = true;
    }

    if (formData.first && formData.first.length > 100) {
      errorName.first = 'This is too long';
      hasError = true;
    }

    if (!formData.last) {
      errorName.last = 'This field is required';
      hasError = true;
    }

    if (formData.last && formData.last.length > 100) {
      errorName.last = 'This is too long';
      hasError = true;
    }

    if (!formData.email) {
      errorName.email = 'This field is required';
      hasError = true;
    }

    if (formData.email && !validateEmail(formData.email)) {
      errorName.email = 'Please enter valid email address';
      hasError = true;
    }

    if (formData.country === 'Select Country') {
      errorName.country = 'This field is required';
      hasError = true;
    }

    if (!formData.terms) {
      errorName.terms = 'This field is required';
      hasError = true;
    }

    if (!formData.recaptcha) {
      errorName.recaptcha = 'Please complete reCaptcha';
      hasError = true;
    }

    setFormError({
      first: errorName.first,
      last: errorName.last,
      email: errorName.email,
      country: errorName.country,
      terms: errorName.terms,
      recaptcha: errorName.recaptcha,
    });

    if (hasError) {
      setFormStatus('ERROR');
    }

    return hasError;
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('LOADING');
    if (isValid()) return;

    try {
      const res = await axios.post(
        'https://www.fightful.com/api/1/contest',
        formData
      );

      if (res.data.success === true) {
        setFormData({
          first: '',
          last: '',
          email: '',
          country: 'Select Country',
          terms: false,
          recaptcha: false,
        });

        setFormStatus('COMPLETE');

        localStorage.setItem('userRegister', true);
      }
    } catch (error) {
      setFormStatus('ERROR');
    }
  };

  const countryOptions = ['United States', 'Canada', 'United Kingdom'];

  return (
    <>
      <Helmet>
        <title>
          Contest: Win An Official Fightful Championship Replica Belt! |
          Fightful
        </title>
      </Helmet>

      {formStatus === 'LOADING' && (
        <div className="flex flex-col items-center justify-center fixed top-0 h-full w-full bg-gray-100 bg-opacity-80">
          <img src={spinner} alt="spinner" width="120" height="120" />{' '}
          <span className="font-bold">Loading...</span>
        </div>
      )}

      {formStatus === 'COMPLETE' ? (
        <section className="md:mt-4">
          <div className="container mx-auto bg-white rounded-xl p-6 border border-gray-300 text-center">
            <img
              className="mx-auto mb-4"
              src={thumbUp}
              alt="Thumbs Up"
              width="160"
              height="160"
            />
            <h1 className="mb-4">Thank You For Registering!</h1>
            <div className="w-14 h-1.5 bg-red-700 mx-auto mb-4" />
            <p className="mb-10 text-lg px-12">
              Fightful will announce the winner once the contest closes.
              <br />
              Meanwhile, please check out the free content on Fightful, or
              Fightful Select for our premium content.
            </p>
            <div className="grid grid-cols-2 gap-4">
              <a
                href="https://www.fightful.com/"
                className=" bg-gray-100 text-gray-700 hover:bg-blue-100 border border-gray-200 rounded-lg h-40 flex flex-col items-center justify-center"
              >
                <img
                  className="mx-auto mb-2"
                  src={fightfulCom}
                  alt="Fightful Select Logo"
                  width="200"
                />
                <span className="text-sm tracking-wide">www.fightful.com</span>
              </a>
              <a
                href="https://www.fightfulselect.com/"
                className=" bg-gray-100 text-gray-700 hover:bg-blue-100 border border-gray-200 rounded-lg h-40 flex flex-col items-center justify-center"
              >
                <img
                  className="mx-auto mb-2"
                  src={select}
                  alt="Fightful Select Logo"
                  width="200"
                />
                <span className="text-sm tracking-wide">
                  www.fightfulselect.com
                </span>
              </a>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className=" md:mt-4">
            <div className="container mx-auto">
              <picture>
                <source
                  srcSet={hero}
                  media="(min-width: 640px)"
                  width="900"
                  height="450"
                />
                <img src={heroMobile} alt="header" width="640" height="800" />
              </picture>
            </div>
          </section>
          <section className="">
            <div className="container mx-auto">
              <form
                className="bg-form-texture bg-cover p-6 md:p-10 border border-gray-400"
                onSubmit={handleOnSubmit}
              >
                {/* {JSON.stringify(formData)} */}
                <div className="pb-4">
                  <div className="text-center">
                    <h1 className="inline-flex leading-none text-lg sm:text-2xl lg:text-3xl">
                      <span className="hidden md:inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-2 -2 24 24"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMinYMin"
                          className="text-red-700 mr-2"
                        >
                          <path
                            fill="currentColor"
                            d="M10 16.379l-6.173 3.245 1.179-6.874L.01 7.882l6.902-1.003L10 .624l3.087 6.255 6.902 1.003-4.995 4.868 1.18 6.874z"
                          />
                        </svg>
                      </span>
                      <span className=" inline-block">
                        Fill Out The Entry Form Below To Enter!
                      </span>
                      <span className="hidden md:inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-2 -2 24 24"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMinYMin"
                          className="text-red-700 ml-2"
                        >
                          <path
                            fill="currentColor"
                            d="M10 16.379l-6.173 3.245 1.179-6.874L.01 7.882l6.902-1.003L10 .624l3.087 6.255 6.902 1.003-4.995 4.868 1.18 6.874z"
                          />
                        </svg>
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2 md:gap-4 mb-2 md:mb-0">
                  <FormItem
                    className="first-name"
                    name="first"
                    label="First Name"
                    value={formData.first}
                    type="input"
                    onChange={handleFormChange}
                    error={formError.first}
                  />
                  <FormItem
                    className="last-name"
                    name="last"
                    label="Last Name"
                    value={formData.last}
                    type="input"
                    onChange={handleFormChange}
                    error={formError.last}
                  />
                </div>

                <div className="grid grid-cols-2 gap-2 md:gap-4">
                  <div className="md:mb-4 col-span-2 md:col-span-1">
                    <FormItem
                      className="email-address"
                      name="email"
                      label="Email Address"
                      value={formData.email}
                      type="input"
                      onChange={handleFormChange}
                      error={formError.email}
                    />
                  </div>
                  <div className="md:mb-4 col-span-2 md:col-span-1">
                    <FormSelect
                      className="country"
                      name="country"
                      label="Country"
                      value={formData.country}
                      options={countryOptions}
                      onChange={handleFormChange}
                      onBlur={handleFormChange}
                      error={formError.country}
                    />
                  </div>
                </div>
                <div className="mt-4 md:mt-2 mb-4">
                  <div className="bg-gray-100 block p-2 rounded border border-gray-500">
                    <FormCheckbox
                      name="terms"
                      label="By entering this contest, I agree to the contest rules
                          and regulations. I consent to receiving electronic
                          communications from Fightful regarding promotions,
                          news, events and all other related communications. I
                          am aware that I may withdraw my consent at any time."
                      className="contest-terms"
                      onChange={handleFormChange}
                      checked={formData.terms}
                      error={formError.terms}
                    />
                  </div>
                </div>
                <div className="mb-8">
                  <ReCAPTCHA
                    sitekey="6LdfTPkZAAAAAP7_Iz7ic4EI-XSNMAm1OoX3bmMG"
                    onChange={(captchaKey) => {
                      setFormData({
                        ...formData,
                        recaptcha: captchaKey,
                      });
                      if (formError.recaptcha) {
                        setFormError({
                          ...formError,
                          recaptcha: '',
                        });
                      }
                    }}
                  />
                  {formError.recaptcha && (
                    <div className="text-red-600 text-sm leading-none mt-1">
                      {formError.recaptcha}
                    </div>
                  )}
                </div>
                <div className="">
                  <button
                    className="w-full bg-red-700 text-white h-14 rounded text-lg font-bold uppercase border-b-4 border-red-900"
                    type="submit"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </section>

          <section className="text-center">
            <div className="container mx-auto bg-gray-700 py-10 px-16 text-lg text-white md:rounded-b-2xl">
              <p>
                <span className="font-bold">No Purchase Necessary</span>. You
                must be 18 years of age or older to enter. US, Canada, and UK
                residents only. See{' '}
                <Link className="text-blue-400 hover:text-blue-600" to="/terms">
                  contest rules
                </Link>{' '}
                for more details.
              </p>
              <p className="bg-red-700 inline-block text-white px-4 font-bold rounded">
                Contest ends MO, ##, 2021.
              </p>
            </div>
          </section>
        </>
      )}

      <Footer />
    </>
  );
};

export default HomePage;
