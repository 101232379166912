import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FormCheckbox = ({
  name,
  className,
  onChange,
  checked = false,
  required = false,
  error = '',
  ...props
}) => {
  return (
    <div className={className}>
      <label htmlFor={name}>
        <table>
          <tbody>
            <tr>
              <td>
                <input
                  id={name}
                  className="w-6 h-6"
                  name={name}
                  type="checkbox"
                  onChange={onChange}
                  value={checked}
                  defaultChecked={checked}
                  {...props}
                />
              </td>
              <td className="text-sm text-gray-700 px-2">
                <span className="">
                  By entering this contest, I agree to the{' '}
                  <Link to="/terms">contest rules and regulations</Link>. I
                  consent to receiving electronic communications from Fightful
                  regarding promotions, news, events and all other related
                  communications. I am aware that I may withdraw my consent at
                  any time.
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </label>
      {error && (
        <div className="text-red-600 text-sm leading-none mt-1">{error}</div>
      )}
    </div>
  );
};

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
};

export default FormCheckbox;
