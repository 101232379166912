import React from 'react';
import PropTypes from 'prop-types';

const FormItem = ({
  name,
  label,
  value = '',
  className = '',
  type = 'text',
  required = false,
  onChange,
  error = '',
  ...props
}) => {
  const isTextarea = type === 'textarea';

  const Element = isTextarea ? 'textarea' : 'input';

  return (
    <div className={`${className}`}>
      {label && (
        <label className="text-sm text-gray-600" htmlFor={name}>
          {label}
        </label>
      )}
      <Element
        className="h-12 px-2 border border-gray-500 rounded w-full"
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        {...props}
      />
      {error && (
        <div className="text-red-600 text-sm leading-none mt-1">{error}</div>
      )}
    </div>
  );
};

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default FormItem;
