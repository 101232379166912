import React from 'react';
import { Helmet } from 'react-helmet-async';

// components imports
import Footer from '@/components/Footer';

// images imports
import thumbUp from '@/assets/img/thump-up.png';
import select from '@/assets/img/select.svg';
import fightfulCom from '@/assets/img/fightfulcom.svg';

const ThankYouPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Win an official fightful championship replica belt | Fightful
        </title>
      </Helmet>

      <section className="md:mt-4">
        <div className="container mx-auto bg-white rounded-xl p-6 border border-gray-300 text-center">
          <img
            className="mx-auto mb-4"
            src={thumbUp}
            alt="Thumbs Up"
            width="160"
            height="160"
          />
          <h1 className="mb-4">Thank You For Registering!</h1>
          <div className="w-14 h-1.5 bg-red-700 mx-auto mb-4" />
          <p className="mb-10 text-lg px-12">
            Fightful will announced the winner once the contest closes via
            social media, website and email. Meanwhile enjoy our free and
            premium content.
          </p>
          <div className="grid grid-cols-2 gap-4">
            <a
              href=""
              className=" bg-gray-100 text-gray-700 hover:bg-blue-100 border border-gray-200 rounded-lg h-40 flex flex-col items-center justify-center"
            >
              <img
                className="mx-auto mb-2"
                src={fightfulCom}
                alt="Fightful Select Logo"
                width="200"
              />
              <span className="text-sm tracking-wide">www.fightful.com</span>
            </a>
            <a
              href=""
              className=" bg-gray-100 text-gray-700 hover:bg-blue-100 border border-gray-200 rounded-lg h-40 flex flex-col items-center justify-center"
            >
              <img
                className="mx-auto mb-2"
                src={select}
                alt="Fightful Select Logo"
                width="200"
              />
              <span className="text-sm tracking-wide">
                www.fightfulselect.com
              </span>
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ThankYouPage;
