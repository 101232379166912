import React from 'react';
import { Helmet } from 'react-helmet-async';

// components imports
import Footer from '@/components/Footer';

const TermsPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Win an official fightful championship replica belt | Fightful
        </title>
      </Helmet>

      <section className="md:mt-4">
        <div className="container mx-auto bg-white p-6 border border-gray-300 rounded-lg">
          <header>
            <h1 className="text-4xl">Belt Contest</h1>
            <p className="text-xl font-serif italic text-gray-500">
              Official Rules & Regulations
            </p>
            <div className="w-14 h-1.5 bg-red-700 ml-6 mt-4 mb-6" />
          </header>
          <main>
            <p className="mb-4 text-gray-600">
              1.{' '}
              <strong>
                NO PURCHASE NECESSARY TO ENTER OR WIN. VOID WHERE RESTRICTED OR
                PROHIBITED BY LAW. ENTRANT MUST HAVE A VALID EMAIL ACCOUNT TO
                ENTER. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING.
                PROMOTION DATES ARE: TBD 1, 2021 to TBD 31, 2021. Open only to
                legal residents of the United States (and D.C), UK, and Canada
                (excluding Quebec);
              </strong>{' '}
              who, at the time of entry, are 18 years of age or older and who
              have reached the age of majority in their state or province. Void
              outside the legal resident’s area or where prohibited or
              restricted by law. This giveaway is subject to all applicable
              federal, state, and local laws and regulations.
            </p>
            <p className="mb-4 text-gray-600">
              2. <strong>HOW TO ENTER.</strong> Complete an entry form found at:
              https://www.fightful.com/TBD-UPDATE-LINK-WHEN-CREATED. Entrants
              must complete the entry form with all information requested of
              them (full name; country; e-mail address). Entrants must agree to
              the Official Rules to count as an official online entry. Limit of
              one entry per person and/or email address. All entries must be
              received and recorded between 12:01 a.m. (EST) on TBD 1, 2021 and
              11:59 p.m. (EST) TBD 31, 2021 (the “Promotion Period”) to be
              eligible. Multiple entries per person or email address are
              prohibited and will void all entries by that entrant.
            </p>
            <p className="mb-4 text-gray-600">
              Sponsor is not responsible for lost, misdirected, late, unsent,
              incomplete, illegible or unintelligible entries or for inaccurate
              entry information, whether caused by the participant or by any of
              the equipment or programming associated with or utilized in this
              Fightful Championship Belt Giveaway (“Sweepstakes”), or by any
              technical or human error that may occur in the transmittal of any
              entry or the processing of any function on the participant’s
              computer system. Participant must notify Sponsor of any changed
              contact information within the Sweepstakes Period to remain
              eligible to win based on entry information. Sponsor reserves the
              right, in its sole discretion, to disqualify any individual found
              to be tampering with the entry process or the operation of the
              Sweepstakes; to be acting in violation of these rules; or to be
              acting with intent to annoy, abuse, threaten or harass any other
              person. Any use of robotic, automatic, programmed or similar
              response methods will void all such entries submitted by such
              methods. Each entry submission must be manually key stroked and
              manually entered by the individual entrant; automated and/or
              repetitive electronic submission of entries (including but not
              limited to entries made using any script, macro, bot or
              sweepstakes service) will be disqualified. All entries must be
              completed by the individual entrant and not by someone else for
              them.
            </p>
            <p className="mb-4 text-gray-600">
              3. <strong>ELIGIBILITY.</strong> Persons in any of the following
              categories are not eligible to participate or win a prize: (a)
              persons who, since August 1, 2021 were or are employees, officers,
              directors or agents of Fightful; their parent companies,
              affiliates, subsidiaries, or dealers; or the service agencies of
              any of the above organizations; (b) individuals engaged in the
              development of, the production or distribution of materials for,
              or the implementation of this Sweepstakes; or (c) employees of,
              persons in the immediate family of, or persons living in the same
              household as any person in any of the preceding categories. For
              the purpose of this Sweepstakes, “immediate family members” are
              defined as spouse, mother, father, in-laws, grandmother,
              grandfather, brother, sister, children, and grandchildren. Any
              Sweepstakes entries received from an ineligible participant will
              be disqualified. Sponsor reserves the right to modify the rules of
              the Sweepstakes in any way at any time, subject to state or
              federal law.
            </p>
            <p className="mb-4 text-gray-600">
              4. <strong>DRAWING:</strong> Sweepstakes participants will enter
              for a chance to win 1 (one) Official Fightful Championship Replica
              Belt The chances of winning depend on the total number of valid
              entries received.
            </p>
            <p className="mb-4 text-gray-600">
              Potential winner will be selected in a random drawing from all
              eligible entries received. Winner will be drawn on or about TBD
              10, 2022 at TBD p.m. (EST). Award of prize and confirmation of
              winner is contingent upon potential winner’s qualification and
              compliance with these Complete Official Rules. Before being
              declared a winner, a potential winner who is a legal resident of
              US, Canada (EXCLUDING Quebec), or the United Kingdom will be
              required to correctly answer, without assistance of any kind,
              whether mechanical or otherwise, a mathematical skill-testing
              question posed by mail or telephone. Potential winner is notified
              by email and telephone. Before being declared a winner, Sponsor
              reserves the right, in its sole discretion, to disqualify and
              randomly select an alternative potential Prize winner if: (a) a
              potential winner does not respond within three (3) days of being
              notified; (b) a prize notification is returned as undeliverable or
              is not responded to; (c) a potential winner fails to provide
              Sponsor with satisfactory proof of age, identity, residency or
              eligibility; (d) potential winner declines acceptance of the
              prize; (e) if in Sponsor’s determination, a potential winner has
              not complied with these Complete Official Rules or (f) if
              potential winner is from Canada and does not answer the required
              skill question correctly and within the time allotted.
            </p>
            <p className="mb-4 text-gray-600">
              Potential Prize winner will be required to sign and return an
              Affidavit of Eligibility, Publicity Release and Liability Waiver
              and any other paperwork required within five (5) days of receipt
              of the affidavit or an alternate winner will be drawn.
            </p>
            <p className="mb-4 text-gray-600">
              The random drawing will be conducted by a third-party selection
              tool whose decisions are final and binding in all matters
              pertaining to this drawing. By participating in this Sweepstakes,
              participants accept and agree to be bound by these Complete
              Official Rules and the decisions of Sponsor, which shall be final
              on all matters.
            </p>
            <p className="mb-4 text-gray-600">
              5. <strong>PRIZES:</strong> Total approximate retail value of this
              Sweepstakes is (USA $1799.99) (Canada $2302.18) (UK £1317.38).
              Prizes will be distributed to the confirmed winner in
              approximately 12-16 weeks from the day of confirmation.
            </p>
            <p className="mb-4 text-gray-600">
              6. <strong>GENERAL CONDITIONS:</strong> Sponsor will contact the
              winner about their prize after they are confirmed through the
              affidavit process.
            </p>
            <p className="mb-4 text-gray-600">
              Prize is non-transferable and no substitution or cash equivalent
              is allowed except in Sponsor’s sole discretion. Winners are solely
              responsible for all applicable federal, state or provincial, and
              local taxes, and any expenses associated with any prizes. Sponsor
              reserves the right to substitute prize of the same or greater
              approximate retail value. Prizes consists only of items
            </p>
            <p className="mb-4 text-gray-600">
              specifically listed as part of the prize. Approximate retail value
              is as of the time the rules were printed, and the value of the
              prize may fluctuate. A winner is not entitled to any difference
              between the approximate retail value and the actual value of the
              prize at the time the prize is awarded.
            </p>
            <p className="mb-4 text-gray-600">
              <strong>
                ANY ATTEMPT BY AN INDIVIDUAL, WHETHER OR NOT AN ENTRANT, TO
                DELIBERATELY DAMAGE, DESTROY, TAMPER OR VANDALIZE THIS WEBSITE
                OR INTERFERE WITH THE OPERATION OF THE SWEEPSTAKES, IS A
                VIOLATION OF CRIMINAL AND CIVIL LAWS AND SPONSOR RESERVES THE
                RIGHT TO SEEK DAMAGES AND DILIGENTLY PURSUE ALL REMEDIES AGAINST
                ANY SUCH ENTRANT TO THE FULLEST EXTENT PERMITTED BY LAW.
              </strong>
            </p>
            <p className="mb-4 text-gray-600">
              7. <strong>PUBLICITY:</strong> By accepting a Prize, where
              permitted by law, each winner grants to Sponsor, and those acting
              pursuant to the authority of Sponsor, the right to print, publish,
              broadcast and use worldwide in any media now known or hereafter
              developed, including, but not limited to, the World Wide Web at
              any time(s), that winner name, portrait, picture, voice, likeness
              (as is or as may be edited, altered, or cropped), information, and
              biographical information as news or information and for
              advertising, trade, and promotional purposes without additional
              compensation or review.
            </p>
            <p className="mb-4 text-gray-600">
              8.{' '}
              <strong>
                RELEASE OF LIABILITY AND AGREEMENT TO HOLD HARMLESS:
              </strong>{' '}
              All entrants, and the Prize winner agree to release and hold
              harmless Fightful Inc.; their parent and subsidiary companies,
              affiliates, subsidiaries, officers, directors, agents, and
              employees; and all others associated with the development and
              execution of this Sweepstakes (and agree to confirm this release
              and agreement to hold harmless in writing(s)) from any and all
              alleged and/or actual actions, demands, losses, settlements
              (whether or not litigation is commenced), claims, damages,
              liability, and costs whatsoever (including attorney fees),
              existing now or in the future, with respect to or in any way
              arising from this Sweepstakes or participation in any
              Sweepstakes-related activity, redeeming Sweepstakes prizes, and/or
              delivery/mis-delivery, acceptance, possession, use, or misuse of a
              prize including, but not limited to, liability for personal
              injury, bodily injury (including wrongful death or disability),
              damage to property, and damage or loss of any other kind.
            </p>
            <p className="mb-4 text-gray-600">
              Without limitation, the Sponsor, its advertising and promotion
              agencies, the Sweepstakes judges, will not be liable for any
              failure of the Web site during the Sweepstakes; for any technical
              malfunction or other problems relating to the telephone network or
              lines, computer online systems, servers, access providers,
              computer equipment, or software; for the failure of any entry to
              be received by the Sponsor, its advertising and promotion
              agencies, or the Sweepstakes judges for any reason including, but
              not limited to, technical problems or traffic congestion on the
              Internet or at any Web site or any combination of the above.
              Further, the Sponsor, its advertising or promotion agencies, the
              Sweepstakes judges, will not be liable for any injury or damage to
              an entrant’s or any other person’s computer related to or
              resulting from participating or downloading any material in the
              Sweepstakes.
            </p>
            <p className="mb-4 text-gray-600">
              Only the stated number of prizes set forth in these Complete
              Official Rules will be awarded. If, for any reason, the
              Sweepstakes is not capable of running as planned due to, but
              without limitation, tampering, unauthorized intervention, fraud,
              human or other error, technical failures, or any other causes
              beyond the control of Sponsor that, in the sole opinion of
              Sponsor, could corrupt or affect the administration, security,
              fairness, integrity, or proper conduct of this Sweepstakes,
              Sponsor reserves the right, at its sole discretion, to cancel,
              terminate, modify, or suspend the Sweepstakes and to draw winner
              from all eligible entries received, pursuant to Rule #3, as of the
              date of termination. Any attempt to deliberately damage any Web
              site or to undermine the legitimate operation of this Sweepstakes
              is a violation of criminal and civil laws, and should such an
              attempt be made, the Sponsor reserves the right to seek remedies
              and damages to the fullest extent permitted by law, including
              criminal prosecution.
            </p>
            <p className="mb-4 text-gray-600">
              9.{' '}
              <strong>
                DISPUTES. THIS SWEEPSTAKES IS GOVERNED BY THE LAWS OF THE STATE
                OF MINNESOTA AND WASHINGTON WITHOUT RESPECT TO CONFLICT OF LAW
                DOCTRINES:
              </strong>{' '}
              As a condition of participating in this Sweepstakes, each
              participant agrees that any and all disputes that cannot be
              resolved between the parties and causes of action arising out of
              or connected with this Sweepstakes shall be resolved individually,
              without resort to any form of class action, exclusively before a
              court located in Washington or Minnesota, having jurisdiction over
              the parties and the subject matter. Further, in any such dispute,
              under no circumstances will participant be permitted to obtain
              awards for and hereby waives all rights to claim punitive,
              incidental, or consequential damages, including reasonable
              attorneys’ fees, other than participant’s actual out-of-pocket
              expenses (i.e., costs associated with entering this Sweepstakes),
              and participant further waives all rights to have damages
              multiplied or increased.
            </p>
            <p className="mb-4 text-gray-600">
              In the event of a dispute over the identity of an Entrant, Entry
              will be deemed submitted by the Authorized Account Holder of the
              e-mail address submitted at time of Entry. Authorized Account
              Holder means the natural person who is assigned to an email
              address by an Internet access provider, online service provider,
              or other organization that is responsible for assigning email
              addresses for the domain associated with the submitted e-mail
              address. Sponsor may ask any Entrant or potential winner to
              provide Sponsor with proof that such party is the authorized
              account holder of the email address associated with the Entry.
              Entry materials/data that have been tampered with or altered, or
              any mass entries or entries generated by a script, macro or use of
              automated devices are void
            </p>
            <p className="mb-4 text-gray-600">
              BY ENTERING THE SWEEPSTAKES, ENTRANT AGREES THAT TO THE EXTENT
              PERMITTED BY APPLICABLE LAW: (A) ANY AND ALL DISPUTES, CLAIMS AND
              CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE SWEEPSTAKES,
              OR ANY PRIZE AWARDED, WILL BE RESOLVED INDIVIDUALLY, WITHOUT
              RESORT TO ANY FORM OF CLASS ACTION; (B) ANY AND ALL CLAIMS,
              JUDGMENTS AND AWARDS WILL BE LIMITED TO ACTUAL THIRD-PARTY,
              OUT-OF-POCKET COSTS INCURRED (IF ANY) NOT TO EXCEED TEN DOLLARS
              ($10.00), BUT IN NO EVENT WILL ATTORNEYS’ FEES BE AWARDED OR
              RECOVERABLE; (C) UNDER NO CIRCUMSTANCES WILL ANY ENTRANT BE
              PERMITTED TO OBTAIN ANY AWARD FOR, AND ENTRANT HEREBY KNOWINGLY
              AND EXPRESSLY WAIVES ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL,
              CONSEQUENTIAL OR SPECIAL DAMAGES, LOST PROFITS AND/OR ANY OTHER
              DAMAGES, OTHER THAN ACTUAL OUT OF POCKET EXPENSES NOT TO EXCEED
              TEN DOLLARS ($10.00), AND/OR ANY RIGHTS TO HAVE DAMAGES MULTIPLIED
              OR OTHERWISE INCREASED; AND (D) ENTRANTS’ REMEDIES ARE LIMITED TO
              A CLAIM FOR MONEY DAMAGES (IF ANY) AND ENTRANT IRREVOCABLY WAIVES
              ANY RIGHT TO SEEK INJUNCTIVE OR EQUITABLE RELIEF. SOME
              JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
              LIABILITY, SO THE ABOVE MAY NOT APPLY TO YOU.
            </p>
            <p className="mb-4 text-gray-600">
              The parties hereto each agree to finally settle all disputes only
              through arbitration; provided, however, the Released Parties shall
              be entitled to seek injunctive or equitable relief in the state
              and federal courts in Tulsa County, Oklahoma and any other court
              with jurisdiction over the parties. In arbitration, there is no
              judge or jury and review is limited. The arbitrator’s decision and
              award is final and binding, with limited exceptions, and judgment
              on the award may be entered in any court with jurisdiction. The
              parties agree that, except as set forth above, any claim, suit,
              action or proceeding arising out of or relating to this
              Sweepstakes shall be resolved solely by binding arbitration before
              a sole arbitrator under the streamlined Arbitration Rules
              Procedures of JAMS Inc. (“JAMS”) or any successor to JAMS. In the
              event JAMS is unwilling or unable to set a hearing date within
              fourteen (14) days of the filing of a “Demand for Arbitration”,
              then either party can elect to have the arbitration administered
              by the American Arbitration Association (“AAA”) or any other
              mutually agreeable arbitration administration service. If an
              in-person hearing is required, then it will take place in Tulsa
              County, Oklahoma. The federal or state law that applies to these
              Official Rules will also apply during the arbitration. Disputes
              will be arbitrated only on an individual basis and will not be
              consolidated with any other proceedings that involve any claims or
              controversy of another party, including any class actions;
              provided, however, if for any reason any court or arbitrator holds
              that this restriction is unconscionable or unenforceable, then the
              agreement to arbitrate doesn’t apply and the dispute must be
              brought in a court of competent jurisdiction in Tulsa County,
              Oklahoma. Sponsor agrees to pay the administrative and
              arbitrator’s fees in order to conduct the arbitration (but
              specifically excluding any travel or other costs of entrant to
              attend the arbitration hearing). Either party may, notwithstanding
              this provision, bring qualifying claims in small claims court.
            </p>
            <p className="mb-4 text-gray-600">
              10. <strong>PERSONAL INFORMATION:</strong> By entering this
              Sweepstakes, each entrant expressly consents to the storing,
              sharing, and use of the personal information submitted with
              his/her entry by Sponsor and its agents and/or representatives in
              accordance with Sponsor’s Privacy Policy and to comply with
              applicable laws, regulations and rules, including, without
              limitation, the storing of your personal information for purposes
              of complying with state record retention requirements. Any
              information entrant provides to Sponsor may be used to communicate
              with entrant in relation to this Sweepstakes or on a Sweepstakes
              winner’s list. All entry and other information submitted may be
              used for email, advertising, trade, and marketing purposes both by
              Sponsor and authorized third parties in accordance with Sponsor’s
              privacy policy, available at
              https://www.fightful.com/privacy-policy
            </p>
            <p className="mb-4 text-gray-600">
              11. <strong>GOVERNING LAW AND LIMITATION OF LIABILITY:</strong>{' '}
              OKLAHOMA RESIDENTS: All issues and questions concerning the
              construction, validity, interpretation and enforceability of these
              Official Rules or the rights and obligations of entrants, Sponsor
              or the Released Parties in connection with the Sweepstakes will be
              governed by and construed in accordance with the internal laws of
              the State of Oklahoma, without giving effect to any choice of law
              or conflict of law rules or provisions that would cause the
              application of any other laws. QUEBEC RESIDENTS: For residents of
              Quebec, any litigation concerning the conduct or administration of
              this publicity contest may be submitted to the Regie dus alcools,
              des courses et des jeux for a ruling. Any litigation concerning
              the awarding of a prize may be submitted to the Regie only for the
              purpose of helping the parties reach a settlement.
            </p>
            <p className="mb-4 text-gray-600">
              12. <strong>WINNERS LIST AND OFFICIAL RULES:</strong> To request a
              copy of the Official Rules, send a self-addressed stamped envelope
              to Shazzu, Inc. PO Box 26086 Broadway PO Toronto, ON M4P 0A8;
              requests must be received by TBD, 2021. Limit one request per
              person or per household. For a written confirmation of the
              winners, send a stamped, self-addressed envelope to Shazzu, Inc.
              PO Box 26086 Broadway PO Toronto, ON M4P 0A8 (available after TBD,
              2021)
            </p>
            <p className="mb-4 text-gray-600">
              13. <strong>SPONSOR CONTACT:</strong> Shazzu, Inc. PO Box 26086
              Broadway PO Toronto, ON M4P 0A8
            </p>
            <p className="mb-4 text-gray-600">
              Copyright / Trademarks (TM) 2021 Shazzu Inc. All Fightful-related
              trademarks are owned by Shazzu Inc. and its related entities or
              are used under license. All rights reserved.
            </p>
            <p className="mb-4 text-gray-600">
              <strong>
                THIS PROMOTION IS NOT SPONSORED OR ADMINISTERED BY FACEBOOK,
                INSTAGRAM OR TWITTER. THESE SOCIAL MEDIA SITES MAY BE USED TO
                ADVERTISE THE CONTEST.
              </strong>
            </p>
          </main>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermsPage;
