import { Route, Switch } from 'react-router-dom';

import DefaultLayout from '@/layout/DefaultLayout';
import HomePage from '@/pages/index';
import ThankYouPage from '@/pages/thankyou';
import TermsPage from '@/pages/terms';

const renderRoutes = () => {
  const CurrentLayout = DefaultLayout;

  return (
    <CurrentLayout>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/thankyou">
          <ThankYouPage />
        </Route>
        <Route exact path="/terms">
          <TermsPage />
        </Route>
      </Switch>
    </CurrentLayout>
  );
};

export default renderRoutes;
