import React from 'react';
// images imports
import logo from '@/assets/img/logo.svg';
import facebook from '@/assets/img/facebook.svg';
import twitter from '@/assets/img/twitter.svg';
import youtube from '@/assets/img/youtube.svg';
import twitch from '@/assets/img/twitch.svg';
import rss from '@/assets/img/rss.svg';

const Footer = () => {
  return (
    <footer className="text-center pt-12 pb-12">
      <div className="container mx-auto">
        <img
          className="mx-auto"
          src={logo}
          width="200"
          height="30"
          alt="logo"
        />
        <div className="inline-flex mt-6">
          <a
            className="inline-flex pr-1 pl-1"
            target="_blank"
            href="https://www.facebook.com/FightfulOnline/"
            rel="noreferrer"
          >
            <img src={facebook} width="30" height="30" alt="facebook" />
          </a>
          <a
            className="inline-flex pr-1 pl-1"
            target="_blank"
            href="https://twitter.com/Fightful"
            rel="noreferrer"
          >
            <img src={twitter} width="30" height="30" alt="twitter" />
          </a>
          <a
            className="inline-flex pr-1 pl-1"
            target="_blank"
            href="https://www.youtube.com/channel/UCS7fkAtgKoJAgoujvNrWIIA"
            rel="noreferrer"
          >
            <img src={youtube} width="30" height="30" alt="youtube" />
          </a>
          <a
            className="inline-flex pr-1 pl-1"
            target="_blank"
            href="https://www.twitch.tv/fightfulgaming"
            rel="noreferrer"
          >
            <img src={twitch} width="30" height="30" alt="twitch" />
          </a>
          <a
            className="inline-flex pr-1 pl-1"
            target="_blank"
            href="https://www.fightful.com/rss.xml"
            rel="noreferrer"
          >
            <img src={rss} width="30" height="30" alt="rss" />
          </a>
        </div>
        <div className="text-gray-500 text-sm mt-4">
          Copyright 2021 Shazzu, Inc. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
